import React from "react"
import Layout from '../../staticcomponents/layout'
import ResearchLandingHeader from "./researchlandingheader";
import Container from '@material-ui/core/Container';
import TeamBioPopUp from "../../staticcomponents/Teambiopopup";
import "./researchlanding.scss";



export default function ResearchLanding () {
    return (
        <>
            <Layout>
                <div className='research-landing-main'>
                    <div className='capsules-bg-right'>
                        <div className='bg-donuts-research-landing'>
                            <ResearchLandingHeader />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
