import React from "react"
import { Grid } from "@mui/material";
import ResearchLandingTile from "../../../staticcomponents/ResearchLandingTile" 
import LoadMoreButton from '../../../staticcomponents/LoadMoreButton';
import Container from '@material-ui/core/Container';
import "./researchlandingheader.scss";


export default function ResearchLandingHeader(){
    return(
       <>
         <Container maxWidth="xl" className="research-main-container">
            <Grid container className="reasearch-landing-cls">
                {/* Title Starts */}
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                        <h1 className='research-landing-title'>research</h1>
                    </Grid>
                    <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
                </Grid>
                {/* Title Ends */}
                <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10} className="research-container">
                    <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="research-card-container">
                    <ResearchLandingTile />
                     <ResearchLandingTile />
                     <ResearchLandingTile />
                     <ResearchLandingTile />
                    </Grid>
                </Grid>
                <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
                {/* Load More Butotn Starts */}
                <LoadMoreButton />
                {/* Load More Butotn Ends */}
            </Grid>
        </Container>
       </>
    )
}

